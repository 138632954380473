import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AlertBarService {

  constructor(private translateService: TranslateService, private matSnackBar: MatSnackBar) {
  }

  error(message: string, alertParam: any = {}): void {
    console.log(message);
    const translatedMessage = this.translateService.instant(message, alertParam || {});

    this.matSnackBar.open(translatedMessage, '', {
      duration: 10000
    });
  }
}
