import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { AdvancedConfirmationDialogData } from './advanced-confirmation-dialog';

@Component({
  selector: 'app-advanced-confirmation-dialog',
  templateUrl: './advanced-confirmation-dialog.component.html',
  styleUrls: ['./advanced-confirmation-dialog.component.scss']
})
export class AdvancedConfirmationDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: AdvancedConfirmationDialogData,
    public dialogRef: MatDialogRef<AdvancedConfirmationDialogComponent>
  ) {
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
