import { SectionAttachmentDto } from '../../shared/model/attachment.model';
import { ApiService } from '../http/api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppStateService } from '../store/app-state.service';

@Injectable()
export class FileUploadService {
  constructor(private apiService: ApiService, private appstate: AppStateService) {
  }

  get processId(): string {
    return this.appstate.getAppState().processId;
  }

  getAttachmentsSection(sectionAttachmentType: string): Observable<any> {
    return this.apiService.get(`processes/${this.processId}/attachments/${sectionAttachmentType}`);
  }

  uploadAttachments(files: FileList, sectionAttachment: SectionAttachmentDto, sectionAttachmentType: string): Observable<any> {
    const formData = new FormData();
    const uploadAttachmentDto = {
      id: sectionAttachment.id,
      filename: files[0].name
    };
    formData.append('uploadAttachmentDto', JSON.stringify(uploadAttachmentDto));
    formData.append('file', files[0], files[0].name);
    return this.apiService.post(`processes/${this.processId}/attachments/${sectionAttachmentType}`, formData);
  }

  deleteAttachments(sectionAttachment: SectionAttachmentDto, sectionAttachmentType: string): Observable<any> {
    return this.apiService.genericDelete(`processes/${this.processId}/attachments/${sectionAttachmentType}`, { body: sectionAttachment });
  }

  downloadAttachment(sectionAttachmentType: string, attachmentId: string, fileName: string): Observable<Blob> {
    return this.apiService.get(`processes/${this.processId}/attachments/${sectionAttachmentType}/${attachmentId}/${fileName}`, { responseType: 'blob' });
  }
}
