import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
    @Output() onFileWasDropped = new EventEmitter<any>();
    @HostBinding('style.background-color') public background = 'white'

    @HostListener('dragleave', ['$event']) public onDragIsLeave(e) {
        e.preventDefault();
        e.stopPropagation();
        this.background = 'lightblue'
    }

    @HostListener('dragover', ['$event']) onDragIsOver(e) {
        e.preventDefault();
        e.stopPropagation();
        this.background = 'white';
    }

    @HostListener('drop', ['$event']) public ondrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.background = 'lightblue'
        let files = e.dataTransfer.files;
        if (files.length > 0) {
            this.onFileWasDropped.emit(files)
        }
    }
}