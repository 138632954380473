import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';


export class AutoLogoutInterceptors implements HttpInterceptor {
  timeToLogout = 60 * 1000 * environment.minutesToLogout;
  timer: any;
  constructor(private keycloakService: KeycloakService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(el => this.setLogoutTimer(this.timeToLogout)))
  }

  private setLogoutTimer(milisecoundsToLogout: number) {
    this.removeTimer(this.timer);
    this.timer = setTimeout(() => {
      this.keycloakService.logout();
    },
      milisecoundsToLogout)
  }

  removeTimer(timer) {
    clearTimeout(timer);
  }

}
