<h2 *ngIf="data.confirmTitleKey"
    mat-dialog-title>
  {{ data.confirmTitleKey | getGender | translate }}
</h2>
<mat-dialog-content
  *ngIf="data.confirmMessageKey"
  [innerHtml]="data.confirmMessageKey | getGender | translate:data.confirmMessageInterpolationParameters">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="data.cancelButtonLabelKey"
          (click)="cancel()"
          class="btn btn-transparent mat-raised-button"
          mat-button>
    {{ data.cancelButtonLabelKey | translate }}
  </button>
  <button *ngIf="data.confirmButtonLabelKey"
          (click)="confirm()"
          class="active btn btn-black mat-raised-button mat-button mat-button-base"
          mat-flat-button>
    {{ data.confirmButtonLabelKey | translate }}
  </button>
</mat-dialog-actions>
