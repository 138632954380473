<a (click)="agreementToDownload(agreementName)">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
        x="0px" y="0px" viewBox="0 0 383.6 383.6"
        style="enable-background:new 0 0 383.6 383.6;height:15px; margin-left:5px;vertical-align: middle;"
        xml:space="preserve" class="svg open">
        <g>
            <path class="st0" d="M327.9,235v108.1H55.7V94.1H160c5.1,0,9.2-4.1,9.2-9.2V64.4c0-5.1-4.1-9.2-9.2-9.2H55.7
                        c-21.5,0-38.9,17.4-38.9,38.9v249.1c0,21.5,17.4,38.9,38.9,38.9h272.3c21.5,0,38.9-17.4,38.9-38.9V235c0-5.1-4.1-9.2-9.2-9.2h-20.5
                        C332,225.8,327.9,229.9,327.9,235z" />
            <path class="st0" d="M350.9,83.4l-90.4,90.4c-4.9,4.9-12.8,4.9-17.7,0l-5.4-5.4c-4.9-4.9-4.9-12.8,0-17.7l90.4-90.4h-63.6
                        c-6.9,0-12.5-5.6-12.5-12.5v-7.7c0-6.9,5.6-12.5,12.5-12.5l119.5,0v119.5c0,6.9-5.6,12.5-12.5,12.5h-7.7c-6.9,0-12.5-5.6-12.5-12.5
                        V83.4z" />
        </g>
    </svg>
</a>