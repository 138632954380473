import {KeycloakOptions} from 'keycloak-angular';

const keycloakConfig: KeycloakOptions = window['env']['keycloakOptions'];

export const environment: Environments = {
  production: false,
  keycloak: keycloakConfig,
  chatAnimationDisabled: true,
  showMessageId: true,
  minutesToLogout: 10,
  http_loader: {
    debounceDelay: 500,
    minDuration: 1000
  },
  baseApiUrl: window['env']['baseApiUrl'],
  name: 'LOCAL'
};

export interface Environments {
  production: boolean;
  keycloak: KeycloakOptions;
  chatAnimationDisabled: boolean;
  showMessageId: boolean;
  minutesToLogout: number;
  http_loader: HttpLoader;
  baseApiUrl: string;
  name: string;
}

export interface HttpLoader {
  debounceDelay: number;
  minDuration: number;
}
