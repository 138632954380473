import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ApplicationRef } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app/app.component";
import { CustomTranslationLoader } from './app/custom-translation-loader';
import { SharedModule } from "./shared/shared.module";
import { MenusModule } from "./features/menus/menus.module";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptors } from './services/http/error.interceptors';
import { AlertBarService } from './shared/alert-bar.service';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AuthGuard } from './services/auth/auth.guard.';
import { environment } from '../environments/environment';
import { AppStateService } from './services/store/app-state.service';
import { FirstLoginModalComponent } from './app/first-login-modal/first-login-modal.component';
import { AgreementCheckboxComponent } from './app/first-login-modal/agreement-checkbox/agreement-checkbox.component';
import { AutoLogoutInterceptors } from './services/http/autologout.interceptors';
import { NgHttpLoaderModule } from 'ng-http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const keycloakService = new KeycloakService();

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    MenusModule,
    HttpClientModule,
    KeycloakAngularModule,
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslationLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [AppComponent, FirstLoginModalComponent, AgreementCheckboxComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    AppStateService
    , AuthGuard
    , { provide: KeycloakService, useValue: keycloakService }
    , { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptors, multi: true, deps: [AlertBarService] }
    , { provide: HTTP_INTERCEPTORS, useClass: AutoLogoutInterceptors, multi: true, deps: [KeycloakService] }
  ],
})
export class AppModule {
  ngDoBootstrap(appRef: ApplicationRef) {
    keycloakService
      .init(environment.keycloak)
      .then(() => {
        console.log('[ngDoBootstrap] bootstrap app');
        appRef.bootstrap(AppComponent);
      })
      .catch(error => console.error('[ngDoBootstrap] init Keycloak failed', error));
  }
}
