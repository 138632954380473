import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import { PlaceholderTranslatePipe } from '../features/wesley-chat/chat-item/chat-message/placeholderTranslate.pipe';
import { AlertBarService } from './alert-bar.service';
import { AdvancedConfirmationDialogComponent } from './components/advanced-confirmation-dialog/advanced-confirmation-dialog.component';
import { DictionaryProxyPipe } from './dictionaryProxy.pipe';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { GenderPipe } from './gender.pipe';
import { NoDblClickMatDirective } from './no-dbl-click-mat.directive';
import { AttachmentsUploadComponent } from './components/attachments-upload/attachments-upload.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [
    DictionaryProxyPipe,
    DragAndDropDirective,
    GenderPipe,
    PlaceholderTranslatePipe,
    NoDblClickMatDirective,
    AdvancedConfirmationDialogComponent,
    AttachmentsUploadComponent,
    AttachmentComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatChipsModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MomentDateModule,
    MatDialogModule,
    MatRadioModule,
    MatIconModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatChipsModule,
    MatCardModule,
    MatAutocompleteModule,
    MomentDateModule,
    MatDialogModule,
    DictionaryProxyPipe,
    DragAndDropDirective,
    GenderPipe,
    MatRadioModule,
    MatIconModule,
    PlaceholderTranslatePipe,
    NoDblClickMatDirective,
    AttachmentsUploadComponent
  ],
  providers: [
    AlertBarService
  ],
})
export class SharedModule {
}
