import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ProcessStatus } from '../../../services/store/app-store.model';
import { AppStateService } from 'src/app/services/store/app-state.service';
import { Subscription } from 'rxjs';

export interface RouteInfo {
  path: string;
  title: string;
}

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'sidebar_summary'
  },
  {
    path: '/chat',
    title: 'sidebar_chat'
  },
  {
    path: '/personal-data',
    title: 'sidebar_data'
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: RouteInfo[] = ROUTES;
  appStateSubscription: Subscription;
  currentProcessStatus: ProcessStatus = this.appStateService.getAppState().processStatus;
  shouldDisplayRegularProcessFields = false;

  constructor(private appStateService: AppStateService) { }

  ngOnInit() {
    this.appStateSubscription = this.appStateService.AppStateChanged.subscribe(data => {
      this.currentProcessStatus = data.processStatus;
      this.shouldDisplayRegularProcessFields = !data.customProcessName;
    });
  }
  ngOnDestroy() {
    this.appStateSubscription.unsubscribe();
  }

  get taxAdvisorName() {
    if (this.appStateService.getAppState().senior) {
      return this.appStateService.getAppState().senior.name;
    }
    return '';
  }

  get taxAdvisorVisible() {
    return this.currentProcessStatus >= ProcessStatus.ATTACHMENTS;
  }

  getReleaseVersion(): void {
    console.log(document.querySelector('nav.navbar').getAttribute('data-version'));
  }

  getEnvName(): string {
    return environment.name
  }

  isNotProduction(): boolean {
    return environment.name !== 'PROD'
  }
}
