import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { ParametrizedObject } from 'src/app/services/store/app-store.model';
import { TranslatationService } from 'src/app/services/translate/translate.service';
import { Subscription } from 'rxjs';

@Pipe({ name: 'placeholderTranslate', pure: false })
export class PlaceholderTranslatePipe implements PipeTransform, OnDestroy {
    value: string;
    resultSubscription: Subscription;
    constructor(private translate: TranslatationService) { }

    transform(element: ParametrizedObject): string {
        let code = element.code;
        let params = element.placeholders;
        if (!code || !params) { return };
        let resolvedPlaceholders = params.map(placeholder => this.resolvePlaceholder(placeholder));
        this.resultSubscription = this.translate.translateObject.get(code, resolvedPlaceholders).subscribe(el => this.value = el);
        return this.value;
    }

    private resolvePlaceholder(input: string): string {
        if (!input) return;
        let startToken = "#{";
        let endToken = "}";

        let endTokenIndex = input.search(endToken);
        if (endTokenIndex != -1) {
            let substringWithNestedPlaceholder = input.substring(0, endTokenIndex);
            let startTokenIndex = substringWithNestedPlaceholder.lastIndexOf(startToken);

            let placeholderValue = input.substring(startTokenIndex + startToken.length, endTokenIndex);
            let resolvedPlaceholder = this.resolvePlaceholder(placeholderValue);

            let beforePlaceholder = input.substring(0, startTokenIndex);
            let afterPlaceholder = input.substring(endTokenIndex + endToken.length);

            return this.resolvePlaceholder(`${beforePlaceholder}${resolvedPlaceholder}${afterPlaceholder}`);
        } else {
            return input.split(",").map(val => {
                const trim = val.trim();
                return trim === "" ? " " : this.translate.translateObject.instant(trim);
            }).join(", ")
        }
    }
    ngOnDestroy() {
        if (this.resultSubscription) {
            this.resultSubscription.unsubscribe();
        }
    }
}