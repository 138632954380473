import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./features/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'attachments',
    loadChildren: () =>
      import('./features/attachments/attachments.module').then(m => m.AttachmentsModule)
  },
  {
    path: 'personal-data',
    loadChildren: () =>
      import('./features/personal-data/personal-data.module').then(m => m.PersonalDataModule)
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./features/wesley-chat/wesley-chat.module').then(m => m.WesleyChatModule)
  },
  {
    path: 'zip/:processId',
    loadChildren: () =>
      import('./features/senior/senior.module').then(m => m.SeniorModule)
  },
  {
    path: '**',
    redirectTo: 'about'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
