<div class="row">
  <div class="col-lg-4 col-sm-12">
    <div class="attachment__title">
      <mat-checkbox [checked]="getCheckboxValue(attachment)"
                    disabled="true">
      </mat-checkbox>
      {{attachment.title | placeholderTranslate  }}
    </div>
    <div>
      {{attachment.subtitle | placeholderTranslate }}
    </div>
    <div *ngIf="attachment.requiredQuantity"
         class="attachment__required-title">
      {{'necessary' | dictionaryProxy:'attachment_component' | translate}}
    </div>
  </div>
  <ng-container *ngIf="isUploadEnabled(); else exceedAttachmentNumber">
    <div (click)="fileInput.click()"
         (onFileWasDropped)="addAttachment($event,attachment)"
         appDragAndDrop
         class="loaderField col-lg-8 col-sm-12">
      <i class="fa fa-long-arrow-up loaderField-icon"></i>
      <span class="loaderField-title">
          {{'add_file' | dictionaryProxy:'attachment_component' | translate}}</span>
      <input #fileInput (change)="addAttachment($event.target.files, attachment)" hidden multiple type="file">
    </div>
  </ng-container>
  <ng-template #exceedAttachmentNumber>
    <span class="attachment__exceeded">{{ 'attachment_component.exceeded' | translate }}</span>
  </ng-template>
</div>

<div class="attachment__required-name"
     [innerHtml]="attachment.subsubtitle | placeholderTranslate">
</div>
<div class="attachment__uploaded">
  <div *ngIf="attachment.attachmentFiles.length !== 0" class="attachment-uploaded__title">
    {{'sent_files' | dictionaryProxy:'attachment_component' | translate}}
  </div>
  <div *ngFor="let attachmentFile of attachment.attachmentFiles;let i= index"
       class="attachment-uploaded__files">
    {{ attachmentFile.filename }}
    <button (click)="deleteAttachment(attachment)" *ngIf="!ProcessAnalyzing">
      <svg class="svg delete" id="Layer_1"
           style="enable-background:new 0 0 383.6 383.6;" version="1.1" viewBox="0 0 383.6 383.6" x="0px"
           xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
                        <g>
                            <path class="st0"
                                  d="M274.6,382H109c-24.4,0-44.6-18.4-47-42.7L42.1,141.8c-12.5-4.1-21.3-15.9-21.3-29.3v-16      c0-25.9,21.1-47,47-47h37.4c3.4-27,26.6-48,54.6-48h60c28,0,51.1,21,54.6,48h41.4c25.9,0,47,21.1,47,47v16      c0,13.4-8.9,25.1-21.3,29.3l-19.9,197.5C319.2,363.7,299,382,274.6,382z M72.3,143.6L92,336.7c0.9,8.8,8.3,15.7,17,15.7h165.6      c8.7,0,16.2-6.9,17-15.7l19.5-193.2H72.3z M67.8,79.6c-9.4,0-17,7.6-17,17v16c0,0.5,0.5,1,1,1H327c0.1,0,0.3,0,0.4,0      c0.1,0,0.3,0,0.4,0h4c0.5,0,1-0.5,1-1v-16c0-9.4-7.6-17-17-17H67.8z M135.8,49.6h108c-3-10.4-12.6-18-24-18h-60      C148.4,31.6,138.8,39.2,135.8,49.6z">
                            </path>
                        </g>
                    </svg>
    </button>
    <button (click)="downloadAttachment(attachmentFile.filename)">
      <svg class="svg open" id="Layer_1"
           style="enable-background:new 0 0 383.6 383.6;" version="1.1" viewBox="0 0 383.6 383.6" x="0px"
           xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
                        <g>
                            <path class="st0" d="M327.9,235v108.1H55.7V94.1H160c5.1,0,9.2-4.1,9.2-9.2V64.4c0-5.1-4.1-9.2-9.2-9.2H55.7
                                        c-21.5,0-38.9,17.4-38.9,38.9v249.1c0,21.5,17.4,38.9,38.9,38.9h272.3c21.5,0,38.9-17.4,38.9-38.9V235c0-5.1-4.1-9.2-9.2-9.2h-20.5
                                        C332,225.8,327.9,229.9,327.9,235z"/>
                          <path class="st0" d="M350.9,83.4l-90.4,90.4c-4.9,4.9-12.8,4.9-17.7,0l-5.4-5.4c-4.9-4.9-4.9-12.8,0-17.7l90.4-90.4h-63.6
                                      c-6.9,0-12.5-5.6-12.5-12.5v-7.7c0-6.9,5.6-12.5,12.5-12.5l119.5,0v119.5c0,6.9-5.6,12.5-12.5,12.5h-7.7c-6.9,0-12.5-5.6-12.5-12.5
                                        V83.4z"/>
                        </g>
                    </svg>
    </button>
  </div>
</div>
