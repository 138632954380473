import {TranslateLoader} from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class CustomTranslationLoader implements TranslateLoader {

  private readonly REGULAR_LANGUAGES = ['en', 'ru', 'pl', 'de'];

  private customProcessName: string;
  private language: string;

  constructor(private http: HttpClient) {
  }

  getTranslation(langCountry: string): Observable<any> {
    // Condition satisfies upon page load. com.json is loaded.
    if (this.REGULAR_LANGUAGES.indexOf(langCountry) !== -1 ) {
      this.language = langCountry;
    } else {
      this.customProcessName = langCountry;
    }

    return this.getTranslations();
  }

  getTranslations(): Observable<any> {
    if (!this.customProcessName) {
      return this.http.get(`/assets/i18n/${this.language}.json`);
    } else {
      return forkJoin(
        this.http.get(`/assets/i18n/${this.language}.json`),
        this.http.get(`${environment.baseApiUrl}/translations/${this.customProcessName}`))
        .pipe(map(data => {
          const res = {};
          data.forEach((obj) => {
            Object.assign(res, obj);
          });
          return res;
        }), catchError(_ => of({})));
    }
  }
}
