import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertBarService } from '../../shared/alert-bar.service';
import { ErrorDto } from './error.model';


export class ErrorInterceptors implements HttpInterceptor {
  constructor(private alertBarService: AlertBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(error => {
          return this.errorHandler(error)
        })
      );
  }

  private errorHandler(error: any): Observable<any> {
    if (error instanceof HttpErrorResponse) {
      if (error.status == 500 && error.error) {
        let errDto: ErrorDto = error.error;
        this.alertBarService.error(errDto.code, errDto.properties)
      }
      return throwError(error);
    }
  }
}
