<div class="logo">
  <a routerLink="/dashboard" class="logo__holder">
    <div class="logo__image">
      <img src="./assets/img/logo.svg"/>
    </div>
    <div class="logo__text">
      Wesl
      <span class="logo__text--yellow" (dblclick)="getReleaseVersion()">EY</span>
      <span class="logo__text--env" *ngIf="isNotProduction()">{{ getEnvName() }}</span>
    </div>
  </a>
</div>

<div class="sidebar-wrapper">
  <ul class="nav">
    <app-sidebar-nav-element [currentProcessStatus]="currentProcessStatus"
                             [shouldDisplayRegularProcessFields]="shouldDisplayRegularProcessFields"
                             *ngFor="let menuItem of menuItems"
                             [menuItem]="menuItem"></app-sidebar-nav-element>
  </ul>

  <ul class="nav nav__bottom">
    <div *ngIf="taxAdvisorVisible; else technicalContact">
      <div class="consultant">
        <div class="consultant__title">
          {{'dedicated_consultant' | dictionaryProxy:'sidebar_component' | translate}}</div>
        <div class="consultant__person">
          <img src="./assets/img/avatar.jpg" class="consultant__avatar"/>
          <div class="consultant__name">
            {{taxAdvisorName}}
          </div>
        </div>
      </div>
    </div>

    <ng-template #technicalContact>
      <div class="consultant consultant__title">
        <a href="mailto:wesley.support@pl.ey.com?subject=Wesley%20problem">
          {{'technical_contact' | dictionaryProxy:'sidebar_component' | translate}}</a>
      </div>
    </ng-template>
  </ul>
</div>
