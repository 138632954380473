import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { ChatDtoModel } from '../store/app-store.model';

@Injectable({ providedIn: "root" })
export class ApiService {
  private readonly url: string;
  constructor(private http: HttpClient) {
    this.url = `${environment.baseApiUrl}`;
  }

  get(requestUrl: string, options?: any): Observable<any> {
    return this.http.get<any>(`${this.url}/${requestUrl}`, options);
  }

  post(requestUrl: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.url}/${requestUrl}`, data);
  }

  put(requestUrl: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.url}/${requestUrl}`, data);
  }

  putJson(requestUrl: string, data: string): Observable<ChatDtoModel> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<ChatDtoModel>(`${this.url}/${requestUrl}`, data, {headers});
  }

  genericDelete(requestUrl: string, data: any): Observable<any> {
    return this.http.request('DELETE', `${this.url}/${requestUrl}`, data);
  }

  delete(requestUrl: string, options: any): Observable<any> {
    return this.http.delete(`${this.url}/${requestUrl}`, options);
  }
}
