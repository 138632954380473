import { Pipe, PipeTransform } from '@angular/core';
import { AppStateService } from '../services/store/app-state.service';

const genders = {
    'male': 'M',
    'female': 'F'
}

@Pipe({ name: 'getGender' })
export class GenderPipe implements PipeTransform {

    constructor(private appState: AppStateService) { }
    transform(value: string): string {
        return `${value}_${genders[this.appState.getAppState().gender]}`
    }
}
