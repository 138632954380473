import { Directive, HostListener } from '@angular/core';
import { environment } from '../../environments/environment';

@Directive({
  selector: '[appNoDblClickMat]'
})
export class NoDblClickMatDirective {

  @HostListener('click', ['$event'])
  clickEvent(event) {
    // see if the srcElement has the disabled property.  If so then it is the actual button.  If not then the user
    // clicked on the button's text (span element)
    const button = (event.target.disabled === undefined) ? event.target.parentElement : event.target;
    button.setAttribute('disabled', true);
    const time = environment.http_loader.debounceDelay + 100;
    setTimeout(function () {
      button.removeAttribute('disabled');
    }, time);
  }
}
