<div class="text-center">
  <h2 class="form-signin-heading" style="color: #333;text-align: center;">
    {{'welcome_in' | dictionaryProxy:'navbar_component' | translate}}
    Wesl
    <span style="color: #ffe600;">EY</span>
    <span class="heading-env" *ngIf="isNotProduction()">{{ getEnvName() }}</span>
  </h2>
  <div class="form-intro">
    {{'welcome_in_helper' | dictionaryProxy:'navbar_component' | translate}}
  </div>
  <form [formGroup]="modalForm">

    <hr *ngIf="isCustomProces()">
    <div class="row form-section" *ngIf="isCustomProces()">
      <div class="col-md-12 form-title">
        {{'personaldata_helper' | dictionaryProxy:'navbar_component' | translate}}
      </div>

      <div class="col-md-3 label-container">
        <label class="select-label title-helper">
          <h5>{{'name' | dictionaryProxy:'forms' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <input matInput type="text" formControlName="name">
      </div>

      <div class="col-md-3 label-container">
        <label class="select-label title-helper">
          <h5>{{'surname' | dictionaryProxy:'forms' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <input matInput type="text" formControlName="surname">
      </div>

      <div class="col-md-3 label-container">
        <label class="select-label title-helper">
          <h5>{{'gender' | dictionaryProxy:'forms' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <mat-radio-group formControlName="gender" aria-labelledby="select-gender-label">
          <mat-radio-button [value]="'FEMALE'">
            <span class="radio-right-margin">{{'female' | dictionaryProxy:'forms' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button [value]="'MALE'">
            <span class="radio-right-margin">{{'male' | dictionaryProxy:'forms' | translate}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col-md-3 label-container">
        <label class="select-label title-helper">
          <h5>{{'position' | dictionaryProxy:'forms.welcome' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <input matInput type="text"  formControlName="position">
      </div>

      <div class="col-md-3 label-container">
        <label class="select-label title-helper">
          <h5>{{'company' | dictionaryProxy:'forms.welcome' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <input matInput type="text"  formControlName="company">
      </div>

      <div class="col-md-3 label-container">
        <label class="select-label title-helper">
          <h5>{{'industry' | dictionaryProxy:'forms.welcome' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <mat-form-field>
          <mat-select formControlName="industry">
            <mat-option *ngFor="let industry of getIndustries()" [value]="industry">
              {{ industry }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <hr *ngIf="!isCustomProces()">
    <div class="row form-section"
         *ngIf="!isCustomProces()">
      <div class="col-md-12 form-title">
        {{'sex_helper' | dictionaryProxy:'navbar_component' | translate}}
      </div>
      <div class="col-md-3">
        <label class="select-label title-helper">
          <h5>{{'gender' | dictionaryProxy:'forms' | translate}}</h5>
        </label>
      </div>
      <div class="col-md-9 text-left">
        <mat-radio-group formControlName="gender" aria-labelledby="select-gender-label">
          <mat-radio-button [value]="'FEMALE'">
            <span class="radio-right-margin">{{'female' | dictionaryProxy:'forms' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button [value]="'MALE'">
            <span class="radio-right-margin">{{'male' | dictionaryProxy:'forms' | translate}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container *ngIf="displayLanguage">
      <hr>
      <div class="row form-section">
        <div class="col-md-12 form-title">
          {{'language_helper' | dictionaryProxy:'navbar_component' | translate}}
        </div>
        <div class="col-md-3">
          <label class="select-label title-helper" id="select-language-label">
            <h5>{{'languages' | dictionaryProxy:'navbar_component' | translate}}
            </h5>
          </label>
        </div>
        <div class="col-md-9 text-left">
          <mat-radio-group aria-labelledby="select-language-label" formControlName="language">
            <mat-radio-button (click)="changeLang(language)" *ngFor="let language of translate.translateObject.getLangs()"
                              [value]="language.toUpperCase()">
                        <span [innerHtml]="language | dictionaryProxy:'navbar_component' | translate"
                              class="radio-right-margin"></span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </ng-container>

    <hr>
    <div class="row form-section">
      <div class="text-left col-md-12">
        <div class="col-md-12 form-title text-center">
          {{'legal_helper' | dictionaryProxy:'navbar_component' | translate}}
        </div>
        <div class="col-md-12">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="privacyConsentAccepted">
              <span class="form-check-sign">
                                <span class="check"></span>
                            </span>{{'agreementPrivacy' | dictionaryProxy:'first_login_modal' | translate}}
            </label>
            <app-agreement-checkbox [agreementName]="'agreementPrivacy'"
                                    (buttonDowloadWasClicked)="downloadAgreement('agreement_privacy')">
            </app-agreement-checkbox>
          </div>
        </div>
        <div class="col-md-12" *ngIf="!isCustomProces()">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="frorConsentAccepted">
              <span class="form-check-sign">
                                <span class="check"></span>
                            </span>{{'agreementFror' | dictionaryProxy:'first_login_modal' | translate}}
            </label>
            <app-agreement-checkbox [agreementName]="'agreementFror'"
                                    (buttonDowloadWasClicked)="downloadAgreement('agreement_fror')">
            </app-agreement-checkbox>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="min-height: 1.5rem;">
        <mat-error *ngIf="modalForm.invalid && modalForm.touched"
                   [innerHtml]="'form_invalid' | dictionaryProxy:'validation.form' | translate">
        </mat-error>
      </div>

      <div class="col-md-12 text-center">
        <div mat-dialog-actions style="justify-content: center;">
          <button [disabled]="modalForm.invalid" mat-button (click)="goToWeslEy()"
                  [mat-dialog-close]="'fffff'"
				  class="nav-link  btn btn-black mat-raised-button start-btn">
			  {{'start' | dictionaryProxy:'navbar_component' | translate}}
          </button>
          <button class="nav-link btn btn-transparent mat-raised-button" (click)="doCancel()">
            {{'log_out' | dictionaryProxy:'navbar_component' | translate}}
            <div class="ripple-container"></div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
