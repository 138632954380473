<h2 mat-dialog-title>{{  ('forms.sure_'  + genderType) | translate }}</h2>
<mat-dialog-actions>
  <button (click)="cancel()"
          class="btn btn-transparent mat-raised-button"
          mat-button>
    {{ 'forms.cancel' | translate }}
  </button>
  <button (click)="confirm()"
          class="active btn btn-black mat-raised-button mat-button mat-button-base"
          mat-flat-button>
    {{ 'forms.confirm' | translate }}
  </button>
</mat-dialog-actions>
