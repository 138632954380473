<div *ngIf="shouldDisplayContent()"
     class="attachment">
  <div class="attachment__header">
    <img class="svg attachment__icon"
         src="{{sectionIcon}}">
    {{ ('attachments.' + sectionAttachmentType) | translate }}
  </div>
  <ng-container *ngIf="attachmentSection">
    <div *ngFor="let attachment of attachmentSection.attachments">
      <app-attachment
        (attachmentsChanged)="handleChangedAttachments()"
        [attachment]="attachment"
        [sectionAttachmentType]="sectionAttachmentType">
      </app-attachment>
    </div>
  </ng-container>
  <ng-container *ngIf="displayOtherAttachments && otherAttachmentSection">
    <div *ngFor="let attachment of otherAttachmentSection.attachments">
      <app-attachment
        (attachmentsChanged)="handleChangedAttachments()"
        [attachment]="attachment"
        [sectionAttachmentType]="otherSectionAttachmentCode">
      </app-attachment>
    </div>
  </ng-container>
  <div class="attachment__types col-lg-12">
    {{'allowed_documents_type' | dictionaryProxy:'attachment_component' | translate}}
  </div>
</div>