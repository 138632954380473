import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConfigurationDto } from './first-login-modal/first-login-modal.model';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from '../services/http/api.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private httpOptions: {
    headers: HttpHeaders
  };

  constructor(private apiService: ApiService) {
  }

  getConfiguration(): Observable<UserConfigurationDto> {
    return this.apiService.get('configuration', this.httpOptions);
  }

  saveConfiguration(conf: UserConfigurationDto): Observable<UserConfigurationDto> {
    return this.apiService.put('configuration', conf);
  }
}
