import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import {
  AppStateModel,
  ChatDtoModel, ChatHistory, ChatHistoryGroup,
  Process,
  ProcessCountry,
  ProcessStatus
} from './app-store.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AppStateService {
  AppStateChanged = new Subject<AppStateModel>();

  private AppState: AppStateModel = {
    language: 'en',
    processCountry: ProcessCountry.RU,
    progress: 0,
    processId: '',
    auth: null,
    currentYear: new Date().getFullYear(),
    gender: 'male',
    currentQuestion: null,
    chatMessages: [],
    oldMessages: [],
    newMessages: [],
    personalData: null,
    personalDataRu: null,
    timestamp: 0,
    processStatus: ProcessStatus.NEW,
    senior: null,
    customProcessName: null
  };

  static shouldAddMessageToGroup(message: ChatHistory, group: ChatHistoryGroup): boolean {
    return group && group.messages.length && group.speaker === message.speaker;
  }

  constructor(private translate: TranslateService) {
  }

  getAppState(): AppStateModel {
    return {...this.AppState};
  }

  setProcessData(processData: Process): void {
    this.AppState = {
      ...this.AppState,
      processId: processData.processId ? processData.processId : this.AppState.processId,
      processStatus: Number(ProcessStatus[processData.status]),
      currentYear: processData.year,
      language: processData.userConfigurationDto && processData.userConfigurationDto.language ?
        processData.userConfigurationDto.language.toLowerCase() : this.AppState.language,
      senior: processData.senior,
      processCountry: processData.processCountry,
      customProcessName: processData.customProcessName
    };
    this.setWesleyChatData(processData.chatDto);
  }

  setWesleyChatData(chatDto: ChatDtoModel): void {
    if (chatDto) {
      chatDto.chatMessages = chatDto.chatMessages.sort((a, b) => a.timestamp - b.timestamp);
      this.AppState = {...this.AppState, ...chatDto};
      this.divideMessages();
      this.AppState.progress = chatDto.progress;
      this.AppStateChanged.next({...this.AppState});
    }
  }

  setLanguage(language: string): void {
    this.AppState = {...this.AppState, language: language};
    this.AppStateChanged.next({...this.AppState});
    this.translate.use(this.AppState.language);
  }

  setProcessId(processId: string): void {
    this.AppState = {
      ...this.AppState,
      processId: processId
    };
    this.AppStateChanged.next({...this.AppState});
  }

  private divideMessages(): void {
    if (this.AppState.currentQuestion || this.AppState.chatMessages.length > 0) {
      if (this.AppState.timestamp == null || this.AppState.timestamp == 0) {
        this.AppState.timestamp = this.AppState.currentQuestion ? this.AppState.currentQuestion.timestamp : this.AppState.chatMessages[this.AppState.chatMessages.length - 1].timestamp;
      }
      this.AppState.oldMessages = this.AppState.chatMessages.filter(message => message.timestamp <= this.AppState.timestamp).reduce(this.groupMessages, []);
      this.AppState.newMessages = this.AppState.chatMessages.filter(message => message.timestamp > this.AppState.timestamp);
      this.AppState.timestamp = this.AppState.currentQuestion ? this.AppState.currentQuestion.timestamp : this.AppState.chatMessages[this.AppState.chatMessages.length - 1].timestamp;
    } else {
      this.AppState.oldMessages = [];
      this.AppState.newMessages = [];
    }
  }

  private groupMessages(previousGroups: ChatHistoryGroup[], newMessage: ChatHistory): ChatHistoryGroup[] {
    const groupedMessages: ChatHistoryGroup[] = [...previousGroups];
    const lastItemIndex: number = groupedMessages.length ? groupedMessages.length - 1 : 0;
    const lastGroup: ChatHistoryGroup = groupedMessages[lastItemIndex];
    if (AppStateService.shouldAddMessageToGroup(newMessage, lastGroup)) {
      lastGroup.messages.push(newMessage);
    } else {
      groupedMessages.push({
        speaker: newMessage.speaker,
        messages: [newMessage]
      });
    }
    return groupedMessages;
  }
}
