import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon-loader',
  templateUrl: './svg-icon-loader.component.html',
  styleUrls: ['./svg-icon-loader.component.scss']
})
export class SvgIconLoaderComponent implements OnInit {
  @Input() icon: string;
  constructor() { }

  ngOnInit() {
  }

}
