<li *ngIf="showPersonalData" routerLinkActive="active" class="nav-item" matTooltipClass='yellow-tooltip'
    [matTooltip]="(( menuItem.title)).concat('_tooltip') | translate"
    matTooltipClass="sidebar-tooltip"
    [matTooltipDisabled]="((( menuItem.title)).concat('_tooltip') | translate).includes('_tooltip')">

    <a class="nav-link" [routerLink]="[menuItem.path]">
        <app-svg-icon-loader [icon]="menuItem.title"></app-svg-icon-loader>
        <div class="nav-item__description">
            {{ menuItem.title | translate }}
        </div>
        <span class="svg icon-info"
            *ngIf="!((( menuItem.title)).concat('_tooltip') | translate).includes('_tooltip')">🛈</span>
    </a>
</li>