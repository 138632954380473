import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileUploadService } from '../../../services/file-upload/fileUpload.service';
import { AppStateService } from '../../../services/store/app-state.service';
import { AppStateModel, ProcessStatus } from '../../../services/store/app-store.model';
import { AlertBarService } from '../../alert-bar.service';
import { ATTACHMENT_FILE_TYPES, SectionAttachmentDto } from '../../model/attachment.model';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit, OnDestroy {

  @Input()
  public attachment: SectionAttachmentDto;
  @Input()
  public sectionAttachmentType: string;

  @Output()
  public attachmentsChanged = new EventEmitter<void>();

  public ProcessStatuses = ProcessStatus;
  public wesleySubscription: Subscription;
  public currentProcessStatus: ProcessStatus = this.wesleyChatService.getAppState().processStatus;

  private readonly maxAttachments = 35;

  constructor(private readonly fileUploadService: FileUploadService,
              private readonly wesleyChatService: AppStateService,
              private readonly alertBarService: AlertBarService) {
  }

  public ngOnInit(): void {
    this.wesleySubscription = this.wesleyChatService.AppStateChanged.subscribe(
      (data: AppStateModel) => {
        this.currentProcessStatus = data.processStatus;
      }
    );
  }

  public ngOnDestroy(): void {
    this.wesleySubscription.unsubscribe();
  }

  public getCheckboxValue(attachment: SectionAttachmentDto): boolean {
    return attachment.requiredQuantity === 0 || (attachment.attachmentFiles && attachment.attachmentFiles.length > 0);
  }

  get ProcessAnalyzing(): boolean {
    return this.currentProcessStatus >= this.ProcessStatuses.ANALYZING;
  }

  isUploadEnabled(): boolean {
    return !this.ProcessAnalyzing && this.attachment.attachmentFiles.length <= this.maxAttachments;
  }

  public addAttachment(files: FileList, sectionAttachment: SectionAttachmentDto): void {
    if (!this.fileTypeDetection(files[0].name)) {
      this.alertBarService.error('attachment_component.incorrectTypeOfDocument');
    } else {
      this.fileUploadService.uploadAttachments(files, sectionAttachment, this.sectionAttachmentType)
        .pipe(
          take(1)
        ).subscribe(
        () => {
          this.attachmentsChanged.emit();
        },() => {
          this.alertBarService.error('attachment_component.incorrectTypeOfDocument');
        });
    }
  }

  public deleteAttachment(sectionAttachment: SectionAttachmentDto): void {
    this.fileUploadService.deleteAttachments(sectionAttachment, this.sectionAttachmentType)
      .pipe(
        take(1)
      ).subscribe(
        () => {
          this.attachmentsChanged.emit();
        }
      );
  }

  public downloadAttachment(fileName: string): void {
    this.fileUploadService
      .downloadAttachment(this.sectionAttachmentType, this.attachment.type, fileName)
      .pipe(take(1))
      .subscribe(data => saveAs(data, fileName));
  }

  public fileTypeDetection(inputFilename: string): boolean {
    const chunks = inputFilename.split('.');
    if (chunks.length < 2) {
      return false;
    }
    const extension = chunks[chunks.length - 1].toLowerCase();
    return ATTACHMENT_FILE_TYPES[extension] !== undefined;
  }

}
