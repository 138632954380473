import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../http/api.service';


@Injectable({ providedIn: "root" })
export class AuthService {
  currentUser: any;

  constructor(private http: ApiService) {
    let token = localStorage.getItem('token');
    if (token) {

    }
  }

  login(credentials) {
    return this.http.post('/api/authenticate', JSON.stringify(credentials))
      .pipe(map(response => {
        let result: { token?: any } = response.json();

        if (result && result.token) {
          //store
          return true;
        }
        return false;
      }));
  }

  logout() {

  }

  isLoggedIn() {

  }

  getCurrentUser() {

  }
}

