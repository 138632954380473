import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/http/api.service';
import { AppStateService } from 'src/app/services/store/app-state.service';
import { TranslatationService } from 'src/app/services/translate/translate.service';
import { environment } from '../../../environments/environment';
import { ProcessCountry } from '../../services/store/app-store.model';
import { FirstLoginModalData, UserConfigurationDto } from './first-login-modal.model';

@Component({
  selector: 'app-first-login-modal',
  templateUrl: './first-login-modal.component.html',
  styleUrls: ['./first-login-modal.component.scss']
})
export class FirstLoginModalComponent implements OnInit {

  public displayLanguage: boolean;

  private INDUSTRIES: string[] = [
    'Administration',
    'Internal Audit',
    'CSR',
    'Finance',
    'HR',
    'IT',
    'Marketing / Communications',
    'Taxes',
    'Legal',
    'Sales',
    'Risk Management',
    'Other'
  ];

  constructor(public dialogRef: MatDialogRef<FirstLoginModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FirstLoginModalData,
              public translate: TranslatationService,
              private apiService: ApiService,
              private wesleyChatService: AppStateService) {
    dialogRef.disableClose = true;
    this.displayLanguage = !this.isRuProcess();

    if (!this.displayLanguage) {
      this.modalForm.get('language').clearValidators();
      this.modalForm.get('language').updateValueAndValidity();
    }
    this.changeLang(this.getInitLanguage());
  }

  modalForm = new FormGroup({
    language: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    frorConsentAccepted: new FormControl('', [conditionallyRequiredValidator(() => !this.isCustomProces()), conditionallyRequiredTrueValidator(() => !this.isCustomProces())]),
    privacyConsentAccepted: new FormControl('', [Validators.required, Validators.requiredTrue]),
    name: new FormControl('', [conditionallyRequiredValidator(() => this.isCustomProces())]),
    surname: new FormControl('', [conditionallyRequiredValidator(() => this.isCustomProces())]),
    position: new FormControl('', [conditionallyRequiredValidator(() => this.isCustomProces())]),
    company: new FormControl('', [conditionallyRequiredValidator(() => this.isCustomProces())]),
    industry: new FormControl('', [conditionallyRequiredValidator(() => this.isCustomProces())])
  });

  ngOnInit() {
    this.modalForm.setValue({
      language: this.getInitLanguage().toUpperCase(),
      gender: this.data.userConfiguration.gender,
      frorConsentAccepted: this.data.userConfiguration.frorConsentAccepted,
      privacyConsentAccepted: this.data.userConfiguration.privacyConsentAccepted,
      name: this.data.userConfiguration.name,
      surname: this.data.userConfiguration.surname,
      position: this.data.userConfiguration.position,
      company: this.data.userConfiguration.company,
      industry: this.data.userConfiguration.industry
    });
  }

  goToWeslEy(): void {
    this.dialogRef.close(this.modalForm.value as UserConfigurationDto);
  }

  downloadAgreement(agreementFileName: string): Subscription {
    return this.apiService.get(`documents?documentId=${agreementFileName}&language=${(this.translate.translateObject.currentLang).toUpperCase()}`, {responseType: 'blob'})
      .subscribe(data => saveAs(data, agreementFileName))
  }

  changeLang(language: string): void {
    if (language) {
      this.wesleyChatService.setLanguage(language.toLowerCase());
    }
  }

  doCancel(): void {
    this.dialogRef.close(null);
  }

  isCustomProces(): boolean {
    return this.data.isCustomProcess;
  }

  isRuProcess(): boolean {
    return this.data && this.data.processCountry && this.data.processCountry === ProcessCountry.RU;
  }

  getIndustries(): string[] {
    return this.INDUSTRIES;
  }

  getEnvName(): string {
    return environment.name
  }

  isNotProduction(): boolean {
    return environment.name !== 'PROD'
  }

  private getInitLanguage(): string {
    if (this.isRuProcess()) {
      return 'RU';
    }
    return this.isCustomProces() || !this.data.userConfiguration.language ? 'PL' : this.data.userConfiguration.language;
  }
}

function conditionallyRequiredValidator(predicate) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  });
}

function conditionallyRequiredTrueValidator(predicate) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.requiredTrue(formControl);
    }
    return null;
  });
}
