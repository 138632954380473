<div class="wrapper" *ngIf="isLogged">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>

  <ng-http-loader
    [debounceDelay]="env.http_loader.debounceDelay"
    [minDuration]="env.http_loader.minDuration"
    [backdrop]="true"
    [spinner]="spinnerStyle.skChasingDots"
    [opacity]=".3">
  </ng-http-loader>

  <div class="main-panel">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
