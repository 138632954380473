import { Injectable } from '@angular/core';
import PerfectScrollbar from "perfect-scrollbar";

@Injectable({
  providedIn: 'root'
})
export class AppResponsiveUtilsService {

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  psForFixedPosition() {
    const isWindows = navigator.platform.indexOf("Win") > -1 ? true : false;
    if (
      isWindows &&
      !document
        .getElementsByTagName("body")[0]
        .classList.contains("sidebar-mini")
    ) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("perfect-scrollbar-on");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("perfect-scrollbar-off");
    }

    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );

    if (elemMainPanel && elemSidebar) {
      elemMainPanel.scrollTop = 0;
      elemSidebar.scrollTop = 0;

      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        let ps = new PerfectScrollbar(elemMainPanel);
        ps = new PerfectScrollbar(elemSidebar);
      }
    }
  }

  // runOnRouteChange(): void {
  //   if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
  //     const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
  //     const ps = new PerfectScrollbar(elemMainPanel);
  //     ps.update();
  //   }
  // }

}
