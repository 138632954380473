import {
  personalDataFormModel,
  PersonalDataRuFormModel
} from 'src/app/features/tax-returns/tax-returns-properties.model';
import { UserConfigurationDto } from 'src/app/app/first-login-modal/first-login-modal.model';
import { SectionAttachmentsDto } from '../../shared/model/attachment.model';

export interface AppStateModel {
  auth: any;
  progress: number;
  processId: string;
  language: string;
  currentYear: number;
  gender: string;
  currentQuestion: CurrentQuestion;
  chatMessages: ChatHistory[];
  oldMessages: ChatHistoryGroup[];
  newMessages: ChatHistory[];
  personalData: PersonalDataDtoModel;
  personalDataRu: PersonalDataRuDtoModel;
  timestamp: number;
  processStatus: ProcessStatus;
  processCountry: ProcessCountry;
  senior: Senior;
  customProcessName: string;
}

export interface Senior {
  name: string;
  email: string;
}

export interface ChatDtoModel {
  currentQuestion: CurrentQuestion;
  chatMessages: ChatHistory[];
  progress: number;
}

export interface ChatHistoryGroup {
  speaker: ChatSpeaker;
  messages: ChatHistory[];
}

export interface ChatHistory {
  speaker: ChatSpeaker;
  parametrizedMessage: ParametrizedObject;
  timestamp: number;
}

export enum ChatSpeaker {
  WESLEY = 'WESLEY',
  CLIENT = 'CLIENT'
}

export interface CurrentQuestion extends PersonalDataModel, PersonalDataRuModel {
  possibleAnswers: string[];
  parametrizedQuestion: ParametrizedObject;
  questionType: string;
  timestamp: number;
  summaryQuestion: boolean;
  params: any;
}

export interface PersonalDataModel {
  personalDataDto: PersonalDataDtoModel;
}

export interface PersonalDataRuModel {
  personalDataRuDto: PersonalDataRuDtoModel;
}


export interface PersonalDataDtoModel {
  personalDataForm: personalDataFormModel;
  personalDataPartsVisibility: personalDataPartsVisibilityDto;
}

export interface PersonalDataRuDtoModel {
  personalDataRuForm: PersonalDataRuFormModel;
  personalDataRuPartsVisibility: PersonalDataRuPartsVisibilityDto;
}

export interface PersonalDataRuPartsVisibilityDto {
  showLowMarginClientFields: boolean;
}

export interface personalDataPartsVisibilityDto {
  showSpouseOrChildren: boolean;
  showBusinessActivities: boolean;
  showDonations: boolean;
  showChildTaxBreaks: boolean;
}

export interface ParametrizedObject {
  code: string;
  placeholders: string[];
}

export interface AnswersDto {
  answers: string[];
  currentQuestion?: CurrentQuestionDto;
}

export interface FormEntriesDto {
  formEntries: { [key: string]: string }[];
  currentQuestion?: CurrentQuestionDto;
}

export interface CalendarFormDto {
  taxResidenceCalendarEntries: TaxResidenceCalendarEntryDto[];
  currentQuestion?: CurrentQuestionDto;
}

export interface CountriesDto {
    countries: string[];
    currentQuestion?: CurrentQuestionDto;
}

export interface TaxResidenceCalendarEntryDto {
  countryCode: string;
  from: string;
  to: string;
}

export interface CurrentQuestionDto {
  parametrizedQuestion: { code: string, placeholder: string[] };
  possibleAnswers: string[];
  questionType: 'SELECT' | 'CHECKBOX' | 'TAX_RESIDENCE_CALENDAR' | 'FORM' | 'JOB_INCOME' | 'OTHER_INCOME' | 'TAX_BREAKS' | 'OPP' | 'PERSONAL_DATA_FORM' | 'PERSONAL_DATA_FORM_HUA' | 'PERSONAL_DATA_FORM_RU' | 'END_CHAT' | 'ORGANIZE_MEETING';
}

export enum ProcessStatus {
  NEW,
  CHAT,
  ATTACHMENTS,
  ANALYZING,
  VERIFICATION,
  SUBMIT,
  FINISHED,
  CANCELED
}

export enum ProcessCountry {
  PL = 'PL',
  PL_HUA = 'PL_HUA',
  RU = 'RU',
  EMPTY ='EMPTY'
}

export enum AttachmentCode {
  OTHER = 'OTHER',
  EMPLOYMENT_INCOME = 'EMPLOYMENT_INCOME',
  OTHER_INCOME = 'OTHER_INCOME',
  DEDUCTIONS = 'DEDUCTIONS',
  BROKERAGE_REPORT = 'BROKERAGE_REPORT',
  OTHER_RU = 'OTHER_RU',
  OTHER_HUA = 'OTHER_HUA'
}

export interface Process {
  processId: string;
  status: ProcessStatus;
  processCountry: ProcessCountry;
  chatDto: ChatDtoModel;
  attachmentDto: SectionAttachmentsDto;
  userConfigurationDto?: UserConfigurationDto;
  senior: Senior;
  year: number;
  customProcessName: string;
}

export interface CreateProcessDto {
  userId: string;
  email: string;
  customProcessDefinitionId: string;
}
