import { KeycloakService } from 'keycloak-angular';
import { ApiService } from '../http/api.service';
import { AppStateService } from './app-state.service';
import { Injectable } from '@angular/core';
import {
  AnswersDto,
  CalendarFormDto,
  ChatDtoModel,
  CountriesDto, CreateProcessDto,
  FormEntriesDto,
  Process,
  ProcessCountry
} from './app-store.model';
import { Observable, Subscription } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppStoreService {
  constructor(private apiService: ApiService,
              private appStateService: AppStateService,
              private keycloakService: KeycloakService) {
  }

  getProcess(): Subscription {
    return (this.apiService
      .get(`processes/${this.appStateService.getAppState().processId}`) as Observable<Process>)
      .subscribe((response) => this.appStateService.setProcessData(response));
  }

  getFirstProcess(): Observable<Process> {
    return this.apiService.get('processes/first') as Observable<Process>;
  }

  getFirstProcessLanguage(): Observable<ProcessCountry> {
    return this.apiService.get('processes/first/language') as Observable<ProcessCountry>;
  }

  resetProcess(): Observable<void> {
    return this.apiService.put('processes-reset', {}) as Observable<void>;
  }

  startProcess(processName: string): Observable<void> {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    const createProcessDto: CreateProcessDto = {
      userId: keycloakInstance.subject,
      email: keycloakInstance.profile.email,
      customProcessDefinitionId: processName
    };
    return this.apiService.post(`processes`, createProcessDto) as Observable<void>;
  }

  startChat(): Subscription {
    return (this.apiService
      .post(`processes/${this.appStateService.getAppState().processId}/resume-command`, {}) as Observable<Process>)
      .subscribe(_ => this.getProcess());
  }

  sendChatResponse(data: CalendarFormDto | FormEntriesDto | AnswersDto | CountriesDto): Observable<ChatDtoModel> {
    return this.apiService
      .putJson(`processes/${this.appStateService.getAppState().processId}/chat`, this.escapeHtml(JSON.stringify(data)));
  }

  sendDeclaration(): Observable<null> {
    return this.apiService.post(`processes/${this.appStateService.getAppState().processId}/resume-command`, {});
  }

  private escapeHtml(html: string): string {
    const text = document.createTextNode(html);
    const p = document.createElement('p');
    p.appendChild(text);
    return p.innerHTML;
  }
}
