import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-agreement-checkbox',
  templateUrl: './agreement-checkbox.component.html',
  styleUrls: ['./agreement-checkbox.component.scss']
})
export class AgreementCheckboxComponent {
  @Input() agreementName: string;
  @Output() buttonDowloadWasClicked = new EventEmitter<string>()
  agreementToDownload(agreement: string): void {
    this.buttonDowloadWasClicked.next(agreement)
  }
}
