import { Component, Input } from '@angular/core';
import { ProcessStatus } from 'src/app/services/store/app-store.model';
import { RouteInfo } from '../sidebar.component';

@Component({
  selector: 'app-sidebar-nav-element',
  templateUrl: './sidebar-nav-element.component.html',
  styleUrls: ['./sidebar-nav-element.component.scss']
})
export class SidebarNavElementComponent {
  @Input() menuItem: RouteInfo;
  @Input() currentProcessStatus: ProcessStatus;
  ProcessStatuses = ProcessStatus;
  @Input() shouldDisplayRegularProcessFields = false;

  get showPersonalData(): boolean {
    if (!this.shouldDisplayRegularProcessFields) {
      return false;
    }
    return this.menuItem.title === 'sidebar_data' ? this.currentProcessStatus >= this.ProcessStatuses.ATTACHMENTS : true;
  }
}
