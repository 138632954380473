import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FileUploadService } from '../../../services/file-upload/fileUpload.service';
import { AttachmentCode } from '../../../services/store/app-store.model';
import { ATTACHMENT_TYPE_ICON_MAP, SectionAttachmentsDto } from '../../model/attachment.model';

@Component({
  selector: 'app-attachments-upload',
  templateUrl: './attachments-upload.component.html',
  styleUrls: ['./attachments-upload.component.scss']
})
export class AttachmentsUploadComponent implements OnInit, OnDestroy {

  @Input()
  public sectionAttachmentType: string;
  @Input()
  public displayOtherAttachments = false;

  public sectionIcon: string;

  public attachmentSection: SectionAttachmentsDto;
  public attachmentsSubscription: Subscription;
  public attachmentState$: Observable<SectionAttachmentsDto>;

  public readonly otherSectionAttachmentCode = AttachmentCode.OTHER;

  public otherAttachmentSection: SectionAttachmentsDto;
  public otherAttachmentsSubscription: Subscription;
  public otherAttachmentState$: Observable<SectionAttachmentsDto>;

  public attachmentCodeIconMap: Map<string, string> = ATTACHMENT_TYPE_ICON_MAP;

  constructor(private readonly fileUploadService: FileUploadService) {
  }

  public ngOnInit(): void {
    this.attachmentState$ = this.fileUploadService.getAttachmentsSection(this.sectionAttachmentType);
    this.attachmentsSubscription = this.attachmentState$.subscribe(data => {
      this.sectionIcon = this.attachmentCodeIconMap.get(this.sectionAttachmentType);
      this.attachmentSection = data;
    });

    if (this.displayOtherAttachments) {
      this.otherAttachmentState$ = this.fileUploadService.getAttachmentsSection(AttachmentCode.OTHER);
      this.otherAttachmentsSubscription = this.otherAttachmentState$.subscribe(data => {
        this.otherAttachmentSection = data;
      });
    }
  }

  public ngOnDestroy(): void {
    this.attachmentsSubscription.unsubscribe();
    if (this.otherAttachmentsSubscription) {
      this.otherAttachmentsSubscription.unsubscribe();
    }
  }

  public handleChangedAttachments(): void {
    this.attachmentsSubscription = this.attachmentState$.subscribe(data => {
      this.attachmentSection = data;
    });
    if (this.displayOtherAttachments) {
      this.otherAttachmentsSubscription = this.otherAttachmentState$.subscribe(otherAttachmentsData => {
        this.otherAttachmentSection = otherAttachmentsData;
      });
    }
  }

  public shouldDisplayContent(): boolean {
    return this.attachmentsLoaded() && (this.isInPopup() || this.hasAtLeastOneAttachment());
  }

  private attachmentsLoaded(): boolean {
    return !!this.attachmentSection;
  }

  private isInPopup(): boolean {
    return this.displayOtherAttachments;
  }

  private hasAtLeastOneAttachment(): boolean {
    return this.attachmentSection.attachments.length > 0;
  }
}
