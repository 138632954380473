import { AttachmentCode, ProcessCountry } from "../../services/store/app-store.model";

export interface DataSectionAttachment {
    sectionAttachmentType: string;
}
export interface SectionAttachmentsDto {
    attachments: SectionAttachmentDto[]
}

export interface SectionAttachmentDto {
    subsubtitle: AttachmentProperties;
    attachmentFiles: AttachmentFileDto[];
    id: string;
    requiredQuantity: number;
    subtitle: AttachmentProperties;
    title: AttachmentProperties;
    type: string;
}

export interface AttachmentProperties {
    code: string;
    placeholders: string[];
}

export interface AttachmentFileDto {
    filename: string;
    comment: string;
}

export const COUNTRY_ATTACHMENT_MAP: Map<ProcessCountry, string[]> = new Map([
  [ProcessCountry.PL, [AttachmentCode.EMPLOYMENT_INCOME, AttachmentCode.OTHER_INCOME, AttachmentCode.DEDUCTIONS]],
  [ProcessCountry.PL_HUA, [AttachmentCode.OTHER_HUA, AttachmentCode.OTHER_INCOME, AttachmentCode.DEDUCTIONS]],
  [ProcessCountry.RU, [AttachmentCode.EMPLOYMENT_INCOME, AttachmentCode.OTHER_RU, AttachmentCode.DEDUCTIONS]]
]);

export const ATTACHMENT_TYPE_ICON_MAP: Map<string, string> = new Map([
  [AttachmentCode.EMPLOYMENT_INCOME, './assets/img/suitcase.svg'],
  [AttachmentCode.OTHER_INCOME, './assets/img/suitcase_other.svg'],
  [AttachmentCode.OTHER_RU, './assets/img/suitcase_other.svg'],
  [AttachmentCode.OTHER_HUA, './assets/img/suitcase_other.svg'],
  [AttachmentCode.DEDUCTIONS, './assets/img/tax.svg']
]);

export const ATTACHMENT_FILE_TYPES: { [key: string]: string } = {
  pdf: 'pdf',
  png: 'png',
  jpg: 'jpg',
  jpeg: 'jpeg',
  bmp: 'bmp',
  doc: 'doc',
  xls: 'xls',
  xlsx: 'xlsx',
  docx: 'docx',
  txt: 'txt',
  zip: 'zip'
};
