<ng-container [ngSwitch]="icon">
    <ng-container *ngSwitchCase="'sidebar_summary'"><svg xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="16px" viewBox="0 0 18 16" version="1.1"
            class="nav-item__icon  replaced-svg">
            <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
            <title>dashboard</title>
            <desc>Created with Sketch.</desc>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Sidebar/Sidebar_Collapsed_Chat" transform="translate(-43.000000, -202.000000)" fill="#616161"
                    fill-rule="nonzero">
                    <g id="Icons" transform="translate(0.000000, 202.000000)">
                        <g id="dashboard" transform="translate(43.000000, 0.000000)">
                            <path
                                d="M11.115751,7.58255728 C11.3310355,7.93947856 11.4545455,8.35563616 11.4545455,8.8 C11.4545455,10.1254834 10.355608,11.2 9,11.2 C7.64439202,11.2 6.54545455,10.1254834 6.54545455,8.8 C6.54545455,7.47451664 7.64439202,6.4 9,6.4 C9.30153117,6.4 9.59036326,6.45316296 9.85722709,6.55042568 L12.0115375,3.54123013 L13.3520988,4.45876987 L11.115751,7.58255728 Z M3.59865026,15.8395948 C1.35037867,14.1879623 0,11.60295 0,8.8 C0,3.9398942 4.02943725,0 9,0 C13.9705627,0 18,3.9398942 18,8.8 C18,11.60295 16.6496213,14.1879623 14.4013498,15.8395948 L14.1829994,16 L3.8170006,16 L3.59865026,15.8395948 Z M13.6288376,14.4 C15.3433805,13.0440056 16.3636364,11.0055929 16.3636364,8.8 C16.3636364,4.8235498 13.066824,1.6 9,1.6 C4.93317593,1.6 1.63636364,4.8235498 1.63636364,8.8 C1.63636364,11.0055929 2.65661949,13.0440056 4.37116236,14.4 L13.6288376,14.4 Z M9,9.6 C9.4518693,9.6 9.81818182,9.24182776 9.81818182,8.8 C9.81818182,8.35817224 9.4518693,8 9,8 C8.5481307,8 8.18181818,8.35817224 8.18181818,8.8 C8.18181818,9.24182776 8.5481307,9.6 9,9.6 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'sidebar_chat'"><svg xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1"
            class="nav-item__icon  replaced-svg replaced-svg">
            <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
            <title>comment-text</title>
            <desc>Created with Sketch.</desc>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Sidebar/Sidebar_Collapsed_Chat" transform="translate(-44.000000, -261.000000)" fill="#616161"
                    fill-rule="nonzero">
                    <g id="Icons" transform="translate(0.000000, 202.000000)">
                        <g id="comment-text" transform="translate(44.000000, 59.000000)">
                            <path
                                d="M8.06943696,12.8 L3.2,15.8433981 L3.2,12.8 L1.6,12.8 C0.7163444,12.8 0,12.0836556 0,11.2 L0,1.6 C0,0.7163444 0.7163444,0 1.6,0 L14.4,0 C15.2836556,0 16,0.7163444 16,1.6 L16,11.2 C16,12.0836556 15.2836556,12.8 14.4,12.8 L8.06943696,12.8 Z M4.8,12.9566019 L7.61056304,11.2 L14.4,11.2 L14.4,1.6 L1.6,1.6 L1.6,11.2 L4.8,11.2 L4.8,12.9566019 Z M4,8.8 L4,7.2 L9.6,7.2 L9.6,8.8 L4,8.8 Z M4,5.6 L4,4 L11.2,4 L11.2,5.6 L4,5.6 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'sidebar_data'"><svg xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1"
            class="nav-item__icon  replaced-svg">
            <!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch -->
            <title>Icons/Calendar</title>
            <desc>Created with Sketch.</desc>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="UI_Elements/Inputs/Chat_Datepicker_NOICON_Chosen_Dark"
                    transform="translate(-416.000000, -43.000000)" fill="#616161">
                    <g id="Icons/Calendar_Dark" transform="translate(416.000000, 43.000000)">
                        <g id="calendar">
                            <path
                                d="M14.4,4.8 L14.4,2.4 L12.8,2.4 L12.8,3.2 L11.2,3.2 L11.2,2.4 L4.8,2.4 L4.8,3.2 L3.2,3.2 L3.2,2.4 L1.6,2.4 L1.6,4.8 L14.4,4.8 Z M14.4,6.4 L1.6,6.4 L1.6,14.4 L14.4,14.4 L14.4,6.4 Z M12.8,0.8 L14.4,0.8 C15.2836556,0.8 16,1.5163444 16,2.4 L16,14.4 C16,15.2836556 15.2836556,16 14.4,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,2.4 C0,1.5163444 0.7163444,0.8 1.6,0.8 L3.2,0.8 L3.2,0 L4.8,0 L4.8,0.8 L11.2,0.8 L11.2,0 L12.8,0 L12.8,0.8 Z"
                                id="Shape" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </ng-container>

</ng-container>