import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppStateService } from '../../../services/store/app-state.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              private readonly appStateService: AppStateService) {
  }

  get genderType(): string {
    return this.appStateService.getAppState().gender === 'male' ? 'M' : 'F';
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
