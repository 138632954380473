import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, mergeMap, switchMap, switchMapTo } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { ApiService } from '../services/http/api.service';
import { AppStateService } from '../services/store/app-state.service';
import { ProcessCountry } from '../services/store/app-store.model';
import { AppStoreService } from '../services/store/app-store.service';
import {
  AdvancedConfirmationDialogData
} from '../shared/components/advanced-confirmation-dialog/advanced-confirmation-dialog';
import {
  AdvancedConfirmationDialogComponent
} from '../shared/components/advanced-confirmation-dialog/advanced-confirmation-dialog.component';
import { AppResponsiveUtilsService } from './app-responsive-utils.service';
import { AppService } from './app.service';
import { FirstLoginModalComponent } from './first-login-modal/first-login-modal.component';
import { FirstLoginModalData, UserConfigurationDto } from './first-login-modal/first-login-modal.model';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  initSubscription: Subscription;

  env = environment;
  spinnerStyle = Spinkit;
  isLogged = false;

  private processCountry;
  private showFirstModal = true;

  constructor(private translate: TranslateService,
              private authService: AuthService,
              private wesleyChatService: AppStateService,
              private dialog: MatDialog,
              private apiService: ApiService,
              private appStoreService: AppStoreService,
              private appStateService: AppStateService,
              private appUtils: AppResponsiveUtilsService,
              private appService: AppService,
              private keycloakService: KeycloakService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: SpinnerVisibilityService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.keycloakService.isLoggedIn().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.isLogged = true;
        this.startApp();
      } else {
        this.isLogged = false;
      }
    });
  }

  private startApp(): void {
    console.log('Starting app...');
    if (!this.isSeniorDownloadingZip()) {
      this.startProcess();
    } else {
      this.spinner.hide();
    }
  }

  private isSeniorDownloadingZip(): boolean {
    return this.keycloakService.getUserRoles(true).includes('wesley_senior') && this.router.routerState.snapshot.url.includes('/zip/')
  }

  private startProcess(): void {
    this.route.queryParams
      .pipe(debounceTime(300))
      .subscribe(params => {
        if (params['process']) {
          this.appStoreService.startProcess(params['process']).subscribe(() => {
            console.log('Process started: ' + params['process']);
            this.initApp();
          }, (errorResponse: HttpErrorResponse) => this.handleStartingProcessError(errorResponse));
        } else {
          console.log('Call init app because no process param in params');
          this.initApp();
        }
      })
  }

  private initApp(): void {
    this.spinner.show();
    console.log('Initializing app...');
    this.initSubscription = this.loadInitData();
    this.appUtils.psForFixedPosition();
    this.spinner.hide();
  }

  private handleStartingProcessError(errorResponse: HttpErrorResponse): void {
    console.log('Starting process error.');
    const message: string = errorResponse.error['properties'] && errorResponse.error['properties']['EX_TRANSLATION_CODE_KEY'] ?
      `exception.modal.${errorResponse.error['properties']['EX_TRANSLATION_CODE_KEY']}` : 'exception.modal.EX_UNEXP';
    this.spinner.hide();
    this.openErrorDialog('exception.modal.title', message, 'exception.modal.log_out', 'exception.modal.proceed').subscribe(value => {
      if (!value) {
        this.logOutUser();
      } else {
        console.log('Call init app in handle starting process error');
        this.initApp();
      }
    });
  }

  private handleNoProcess(): void {
    console.log('No process found.');
    const message = 'exception.modal.EX_NO_PROCESS';
    this.spinner.hide();
    this.openErrorDialog('exception.modal.title', message, 'exception.modal.log_out', null).subscribe(() => {
      this.initSubscription.unsubscribe();
      this.logOutUser();
    });
  }

  private openErrorDialog(title: string, body: string, cancelLabel: string, confirmLabel: string): Observable<boolean> {
    const dialogData: AdvancedConfirmationDialogData = {
      cancelButtonLabelKey: cancelLabel,
      confirmButtonLabelKey: confirmLabel,
      confirmTitleKey: title,
      confirmMessageKey: body
    };
    return this.dialog.open(AdvancedConfirmationDialogComponent, {data: dialogData, disableClose: true}).afterClosed();
  }

  private loadInitData(): Subscription {
    console.log('Load init data');
    return this.appStoreService.getFirstProcessLanguage()
      .pipe(
        mergeMap(lang => {
          this.setAvailableLanguages(lang);
          return this.appService.getConfiguration();
        }),
        mergeMap(conf => this.validateConfiguration(conf)),
        switchMapTo(this.appStoreService.getFirstProcess())
      ).subscribe(process => {
        if (!process) {
          this.handleNoProcess();
        } else {
          process.chatDto.chatMessages = process.chatDto.chatMessages.sort((a, b) => a.timestamp - b.timestamp);
          this.appStateService.setProcessData(process);
          this.translate.use(process.processCountry.toLowerCase());
        }
      }, () => this.handleNoProcess());
  }

  private setAvailableLanguages(processCountry: ProcessCountry): void {
    this.processCountry = processCountry;
    if (processCountry) {
      this.translate.langs = [];
      switch (processCountry) {
        case ProcessCountry.RU:
          this.translate.setDefaultLang('ru');
          this.translate.addLangs(['ru']);
          break;
        case ProcessCountry.PL:
          this.translate.addLangs(['pl', 'en', 'de']);
          this.translate.setDefaultLang('pl');
          break;
        case ProcessCountry.PL_HUA:
          this.translate.addLangs(['en']);
          this.translate.setDefaultLang('en');
          break;
        default:
          this.translate.addLangs(['en', 'pl']);
          break;
      }
    }
  }

  private validateConfiguration(conf: UserConfigurationDto): Observable<UserConfigurationDto> {
    if (this.isUpdateUserDataNotRequired(conf)) {
      this.setLanguage(conf);
      return of(conf);
    } else {
      this.spinner.hide();
      return this.openConfigModal(conf);
    }
  }

  private openConfigModal(conf: UserConfigurationDto): Observable<UserConfigurationDto> {
    const firstLoginModalData: FirstLoginModalData = {
      isCustomProcess: this.isCustomProcess(),
      processCountry: this.processCountry,
      userConfiguration: conf
    };
    return this.dialog.open(FirstLoginModalComponent, {
      data: firstLoginModalData,
      panelClass: 'login-dialog'
    }).afterClosed().pipe(
      switchMap(newConf => {
        this.showFirstModal = false;
        if (!newConf) {
          this.spinner.show();
          this.initSubscription.unsubscribe();
          this.logOutUser();
          this.spinner.hide();
          return of(null);
        }
        if (this.processCountry === ProcessCountry.RU) {
          newConf.languag = 'ru';
        }
        return this.appService.saveConfiguration(newConf);
      })
    );
  }

  private isCustomProcess(): boolean {
    return !this.processCountry || this.processCountry === 'EMPTY';
  }

  private setLanguage(userData: UserConfigurationDto): void {
    if (userData && userData.language) {
      const userLang = userData.language.toLowerCase();
      this.translate.use(userLang);
      this.translate.setDefaultLang(userLang);
    }
  }

  private logOutUser(): void {
    console.log('Operation is canceled by a user. Logging out...');
    this.keycloakService.logout().catch(() => {
      console.error('Cannot log out user with invalid configuration');
    });
  }

  ngOnDestroy(): void {
    this.initSubscription.unsubscribe();
  }

  isUpdateUserDataNotRequired(config: UserConfigurationDto): boolean {
    return this.processCountry == 'EMPTY' && this.showFirstModal == false ||
      this.processCountry !== 'EMPTY' && config.frorConsentAccepted && config.privacyConsentAccepted && config.gender != null && config.language != null;

  }
}
